import React from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
const Area = () => {
  return (
    <>
      <Navbar
        SeoTitle="AREA WE COVER - .natick airport taxi car services"
        SeoDesc=" We are at your disposal 24X7 to drop and pick up as per your continence. You no more have to depend on cab availability to travel to and from Airports. Boston Taxi Services Logan Airport Taxi Logan Airport Taxi Services Taxi Service Logan Logan Airport Car Service Boston Car Service"
      />
      <div className="main_container ">
        <div className="section_gap pb_0">
          <h1 className="text_center main_title mb-3">
            AREA<span>&nbsp; WE COVER</span>
          </h1>
          <p className="text_center mb-4">
            We are most reliable and larget fleet of airport transportation
            service provider not only for Natick, MA but entire Massachusetts.
            We do offer to/from the airport taxi & acr service with leet of
            Minivan, Sedan, Taxi, SUV and shuttle services. You can also add car
            seat.
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Area;

import React from "react";
import Footer from "../Components/Footer";
import Navbar from "../Components/Navbar";

const OurServices = (props) => {
  return (
    <>
      <Navbar   SeoTitle={props.SeoTitle} SeoDesc={props.SeoDesc}/>
      <div className="main_container mb-100">
        <div className="section_gap pb_0">
          <h1 className="text_center main_title mb-5">
            {props.Title} <span>{props.ColorTitle}</span>
          </h1>

          <h2 className="mb-2 blue_color">{props.SubTitle}</h2>
          {props.para && <p className="mb-3">{props.para}</p>}
          {props.parab && <p className="mb-3">{props.parab}</p>}
          {props.title1 && <h3 className="mb-3">{props.title1}</h3>}
          {props.para1a && <p className="mb-3">{props.para1a}</p>}
          {props.para1b && <p className="mb-3">{props.para1b}</p>}
          {props.para1c && <p className="mb-3">{props.para1c}</p>}
          {props.title2 && <h3 className="mb-3">{props.title2}</h3>}
          {props.para2a && <p className="mb-3">{props.para2a}</p>}
          {props.para2a && <p className="mb-3">{props.para2a}</p>}
          {props.para2c && <p className="mb-3">{props.para2c}</p>}
          {props.title3 && <h3 className="mb-3">{props.title3}</h3>}
          {props.para3a && <p className="mb-3">{props.para3a}</p>}
          {props.para3b && <p className="mb-3">{props.para3b}</p>}
          {props.para3c && <p className="mb-3">{props.para3c}</p>}
          {props.title4 && <h3 className="mb-3">{props.title4}</h3>}
          {props.para4a && <p className="mb-3">{props.para4a}</p>}
          {props.para4b && <p className="mb-3">{props.para4b}</p>}
          {props.para4c && <p className="mb-3">{props.para4c}</p>}
          {props.title5 && <h3 className="mb-3">{props.title5}</h3>}
          {props.para5a && <p className="mb-3">{props.para5a}</p>}
          {props.para5b && <p className="mb-3">{props.para5b}</p>}
          {props.para5c && <p className="mb-3">{props.para5c}</p>}
          {props.title6 && <h3 className="mb-3">{props.title6}</h3>}
          {props.para6a && <p className="mb-3">{props.para6a}</p>}
          {props.para6b && <p className="mb-3">{props.para6b}</p>}
          {props.para6c && <p className="mb-3">{props.para6c}</p>}
        </div>
      </div>
      <Footer />
    </>
  );
};
export default OurServices;

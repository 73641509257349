import React from "react";
import Car from "../Components/Car";
import Footer from "../Components/Footer";
import Navbar from "../Components/Navbar";

const Fleet = () => {
  return (
    <>
      <Navbar
        SeoTitle="Logan Airport Limo Services, Car Rental Boston Airport - Fleets"
        SeoDesc="Logan Airport Limo Services - Our comprehensive fleet of Car Rental Boston Airport Services, and Logan Airport Limo Services are safely and promptly bring you to your destination."
      />
      <div className="mt-5"></div>
      <Car />
      <div className="mt-5"></div>
      <Footer />
    </>
  );
};
export default Fleet;

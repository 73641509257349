import React from "react";
import Footer from "../Components/Footer";
import Navbar from "../Components/Navbar";

const Privacy = () => {
  return (
    <>
      <Navbar  SeoTitle="" SeoDesc=""/>
      <div className="main_container mb-100">
        <div className="section_gap pb_0">
          <h1 className="text_center main_title mb-5">
            PRIVACY <span>POLICY</span>
          </h1>
          <h3 className="mb-2 blue_color">
            Airport Taxi and car Service Natick MA
          </h3>
          <p className="mb-2">
            Natick taxi and Car Service Customer Privacy is most Priority and we
            would be glad if you can spare some time and go through our privacy
            policy and terms of use. Natickairporttaxi.Com is very concerned
            about your privacy and assures you that we exceed industry standards
            in safeguarding your personal details and identity information that
            you have ever shared with us
          </p>
          <h3 className="mb-2 blue_color">Tracking Traffic on our sites</h3>
          <p className="mb-2">
            To track our web traffic we use third party software form Google
            that are fairly open standard. So your geo location and other site
            usage are tracked but the user remains anonymous all the time. We
            use this data for our internal reporting purposes.
          </p>
          <h3 className="mb-2 blue_color">Use of Cookies and IP logging</h3>
          <p className="mb-2">
            On our blog that runs WordPress, certain plug-in might use cookies
            or log your IP for safety and anti fraud techniques. We assure you
            that they are all open standard methods and used by most of the
            websites and blogs today. So You are in safe hands. When you submit
            a request we might track your IP as well to validate the request and
            avoid our spamming of our sites.
          </p>
          <h3 className="mb-2 blue_color">Use of Personal Information</h3>
          <p className="mb-2">
            We sometimes ask you for personal information like email, Name and
            websites while commenting on the blogs or sending a contact/query
            request. While Email data is not public and your name and website
            address that you share is public on comments. Under no circumstances
            will these protected data be shared with a third entity. (Refer to
            spamming cases)
          </p>
          <h3 className="mb-2 blue_color">Spamming our Site</h3>
          <p className="mb-2">
            When our automated spam detection system detects your comments or
            queries as spam we might report your IP and email address for black
            listing. This is a global practice to nail the spam demon.
          </p>
          <h3 className="mb-2 blue_color">Content on Site</h3>
          <p className="mb-2">
            We make sure the content on our site is original. Whenever we use
            graphics by other creators we make sure to provide appropriate
            credentials. In case you find some of them lacking a credit or if
            you find your work on our site and do not approve it then let us
            know we shall remove it within 48 hours.
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Privacy;

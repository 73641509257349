import React from "react";
import Footer from "../Components/Footer";
import Navbar from "../Components/Navbar";

const AreaWeServe = (props) => {
  return (
    <>
      <Navbar   SeoTitle={props.SeoTitle} SeoDesc={props.SeoDesc} />
      <div className="main_container mb-100">
        <div className="section_gap pb_0">
          <h1 className="text_center main_title mb-5">
            {props.Title} <span> {props.ColorTitle}</span>
          </h1>

          <h2 className="mb-2 blue_color">{props.SubTitle}</h2>
          <p className="mb-3">{props.para1}</p>
          <p className="mb-3">{props.para2}</p>
          <p className="mb-3">{props.para3}</p>
          <p className="mb-3">{props.para4}</p>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default AreaWeServe;

import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import styles from "./Navbar.module.css";
import image1 from "./../Assets/image1.png";
import Group9 from "./../Assets/Group9.png";

function Navbar(props) {
  const [mobile, setMobile] = useState(false);

  const handleMobile = () => {
    setMobile((state) => !state);
  };
  return (
    <>
      <Helmet>
        <title>{props.SeoTitle}</title>
        <meta name="description" content={props.SeoDesc} />
      </Helmet>
      <nav className={styles.nav}>
        <ul>
          <img className={styles.img1} src={image1} alt="Logo" />
          <div className={styles.items}>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/about-us">About</Link>
            </li>
            <li>
              <Link to="/services">Services</Link>
            </li>
            <li>
              <Link to="/area-we-cover">Area we Cover</Link>
            </li>
            <li>
              <Link to="/fleets">Fleets</Link>
            </li>
            <li>
              <Link to="/airport-minivan">Airport Minivan</Link>
            </li>
          </div>

          <button>Login</button>
          <img
            onClick={handleMobile}
            className={styles.hamburger}
            src={Group9}
            alt="Logo"
          />
        </ul>
      </nav>
      {mobile && (
        <div className={styles.items2}>
          <hr></hr>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/about-us">About</Link>
          </li>
          <li>
            <Link to="/services">Services</Link>
          </li>
          <li>
            <Link to="/area-we-cover">Area we Cover</Link>
          </li>
          <li>
            <Link to="/fleets">Fleets</Link>
          </li>
          <li>
            <Link to="/airport-minivan">Airport Minivan</Link>
          </li>
        </div>
      )}
    </>
  );
}

export default Navbar;
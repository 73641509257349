import React from "react";
import Footer from "../Components/Footer";
import Navbar from "../Components/Navbar";

const Terms = () => {
  return (
    <>
      <Navbar  SeoTitle="" SeoDesc=""/>
      <div className="main_container mb-100">
        <div className="section_gap pb_0">
          <h1 className="text_center main_title mb-5">
            TERMS <span>AND CONDITIONS</span>
          </h1>

          <h2 className="mb-2 blue_color">
            Natick Airport Taxi & Car Service provides to Logan Airport Taxi &
            Town Car service from/to Boston Logan and anywhere in Massachusetts.
          </h2>
          <p className="mb-3">
            <strong>
              Terms and Conditions: Natick Airport Taxi & Car Services.
            </strong>
          </p>
          <ul className="custom_list">
            <li>
              We accept all types of Credit Cards & PayPal.{" "}
              <strong>Natick Airport Taxi & Car Services (DBA)</strong> is
              incorporated by Logan Airport Car, Inc.
            </li>
            <li>
              <strong>"LOGAN AIRPORT CAR, INC"</strong> will appear on the
              Credit Card / PayPal Billing Statement.
            </li>
            <li>
              <strong>Service Hours:</strong> We provide 24/7 Airport
              Transportation - except State of Emergency.
            </li>
            <li>
              <strong> Payment Options:</strong> PayPal or Credit Card. An
              option of payment must be required to complete online booking. All
              PayPal payment goes to under Corporate email to
              :(loganairportcarservice@gmail.com).
            </li>
            <li>
              <strong> Luggage Restrictions: </strong>There are maximum luggage
              restrictions depending on the vehicle Capacity. Please check your
              vehicle luggage capacity from the fleet/Fare Quote page. Any
              additional Luggage may cost each $3.00 – Please Specify the
              Luggage amount while booking.
            </li>
            <li>
              <strong> Changes: </strong>Change/Modify/Cancellation is free of
              cost before 4 (Four) hours of actually scheduled pickup. Please
              simply email info@natickairporttaxi.com.com for the change or
              Cancel Request.
            </li>
            <li>
              <strong> Refund: </strong>Any refund which is Paid by
              PayPal/Credit Card will be processed within 24 (Twenty Four)
              hours.
            </li>
            <li>
              <strong>No-Show: </strong>In case of passenger no-show. A no-show
              (full fare) will occurred, no refund will be allowed. Passenger
              must contact us – or- email for reschedule/change/cancel an
              existing reservation 4 (Four) hours before scheduled pickup.
            </li>
            <li>
              <strong> Cancellation:</strong> Cancellation before 4 (Four) hours
              - will be refunded in full. Less than 4 (Four) hours a refund will
              not be allowed.
            </li>
            <li>
              <strong> Reservation: (DAY) From 8:00 A.M - 8:00 P.M.</strong>{" "}
              Online Reservation Required at least 4 (Four) hours advance to
              schedule a Car. (NIGHT) From 8:00 PM - 8:00 AM required minimum 8
              (Eight) hours for online reservation. Need Urgent Booking, Please
              Call us (508) 907-6115.
            </li>
            <li>
              <strong> Tolls/Fees:</strong> All Tolls & Charges are Included in
              fare as per Airport Authority Rules
            </li>
            <li>
              <strong> State Emergency:</strong> In case of State Emergency or
              snow Storms or heavy snowfall, we might have to cancel the
              service, we will contact passenger before cancellation. Canceled
              for Natural Causes, Change/Refund can be requested/Issued.
            </li>
            <li>
              <strong> To Airport Pickup:</strong> Waiting Time: All Pick-up to
              the Airport- Driver will wait up to 15 (fifteen) minutes. Please
              contact driver, if you need more time. Please contact driver, if
              you need more time or No-Show may occurred.
            </li>
            <li>
              <strong> From Airport Pickup:</strong> Waiting Time: All
              Pickup-from the airport- Driver will wait up to 1 hours from the
              actual flight arrival/landing time. If you need more than 1 (ONE)
              hour - Please contact driver, if you need more time or No-Show may
              occurred.
            </li>
            <li>
              <strong> Waiting Charge:</strong> Waiting charge is $1.00 per
              minute after Maximum waiting Minutes.
            </li>
          </ul>
          <p className="mb-2">
            <strong>
              If You Have Any Questions? Please Call: (508) 907-6115.
            </strong>
          </p>
          <h3 className="mb-2 blue_color">
            <strong>
              NOTE: At Boston Logan Airport Pickup - Please follow the "LIMOS"
              sign to meet your driver in LIMO PICKUP stand.
            </strong>
          </h3>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Terms;

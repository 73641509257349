import React from "react";
import Footer from "../Components/Footer";
import Navbar from "../Components/Navbar";

const Contact = () => {
  return (
    <>
      <Navbar
        SeoTitle="Logan Airport Transportation Boston Airport Transportation Contact Us"
        SeoDesc="Logan Airport Transportation  - NatickAirport Taxi Service Provides Logan Airport Transportation, Boston Airport Transportation and Premium Transportation Service for our valuable Travelers."
      />
      <div className="main_container mb-5 ">
        <div className="section_gap pb_0">
          <h1 className="text_center main_title mb-5">
            <span> Contact </span> Us
          </h1>
        </div>
        <div className="two_col_wrapper mb-100">
          <div className="two_col_wrapper_single">
            <form className="contact_form">
              <input type="text" placeholder="Name" />
              <input type="email" placeholder="Email" />
              <input type="text" placeholder="Phone" />
              <textarea rows={5} type="text" placeholder="Message"></textarea>
              <button className="btn_main">Submit</button>
            </form>
          </div>
          <div className="two_col_wrapper_single contact_info">
            <h4>Contact Information</h4>
            <p>Address Natick, MA 01760 </p>
            <p>
              Call Us : <a href="tel:5089076115">(508) 907-6115 </a>
            </p>
            <p>
              E-mail :
              <a href="mailto:info@natickairporttaxi.com">
                info@natickairporttaxi.com
              </a>
            </p>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m10!1m8!1m3!1d23607.26792002866!2d-71.358386!3d42.301818!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sus!4v1665482290428!5m2!1sen!2sus"
              width="600"
              height="200"
              style={{ border: "0" }}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default Contact;

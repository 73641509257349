import React from "react";
import About from "../Components/About";
import Advantages from "../Components/Advantages";
import Callnow from "../Components/Callnow";
import Car from "../Components/Car";
import Footer from "../Components/Footer";
import Home from "../Components/Home";
import Navbar from "../Components/Navbar";
const Index = () => {
  return (
    <>
      <Navbar
        SeoTitle="Logan Airport Car Service, Taxi Service Logan - Natick Airport Taxi"
        SeoDesc="Logan Airport Car Service - Natick Airport Taxi is most trusted reliable Taxi Service Logan and Logan Airport Car Service. Our first preference is client’s satisfaction in order to drop the passenger at the preferred destination with safely."
      />
      <Home />
      <About />
      <Car />
      <Advantages />
      <Callnow />
      <Footer />
    </>
  );
};

export default Index;

import React from "react";
import { Link } from "react-router-dom";
import styles from "./Footer.module.css";
import image1 from "./../Assets/image1.png";
import fb from "./../Assets/fb.png";
import twitter from "./../Assets/twitter.png";
import yelp from "./../Assets/yelp.png";
import trip from "./../Assets/tripadvisor.png";
import background from "./../Assets/foot.png";

function Footer() {
  return (
    <div id="footer" className={styles.Main}>
      <div className={styles.Top}>
        <div className={styles.header}>
          <h1>Traveling to Logan Airport</h1>
          <h1>from your Location ?</h1>
        </div>
        <div className={styles.p}>
          <p>
            If you are looking for safe and comfortable transportation services
            then Natick Airport Taxi & car Services is the place to contact
            (508) 907-6115. We aim at delivering convenient and affordable car
            services across the Massachusetts.
          </p>
        </div>
        <button className={styles.button}>Book Now</button>
      </div>
      <div className={styles.bottom}>
        <div className={styles.bottom1}>
          <div  id="company-info" className={styles.one}>
            <img src={image1} />
            <p>
              Natick Airport Taxi is most trusted reliable Logan airport taxi
              and car service in Boston.
            </p>
          </div>
          <div className={styles.two} id="footer_menu_list">
            <div className={styles.subOne}>
              <h3>Quick Links</h3>
              <p>
                <Link to="/contact-us">Contact Us </Link>
              </p>
              <p>
                <Link to="/wellesley-ma">Wellesley, MA </Link>
              </p>
              <p>
                <Link to="/framingham-ma">Framingham, MA</Link>
              </p>
              <p>
                <Link to="/marlborough-ma">Marlborough, MA</Link>
              </p>
              <p>
                <Link to="/terms-and-conditions">Terms and Conditions</Link>
              </p>
              <p>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </p>
            </div>
            <div className={styles.subTwo}>
              <h3>Our Services</h3>
              <p>
                <Link to="/boston-taxi-services">Boston Taxi Services</Link>
              </p>
              <p>
                <Link to="/logan-airport-taxi-services">
                  Logan Airport Taxi
                </Link>
              </p>
              <p>
                <Link to="/logan-airport-taxi-services">
                  Logan Airport Taxi Services
                </Link>
              </p>
              <p>
                <Link to="/boston-taxi-services">Boston Car Service</Link>
              </p>
              <p>
                <Link to="/boston-taxi-services">Boston Airport Shuttle</Link>
              </p>
              <p>
                <Link to="/car-services-to-logan">Car Services to Logan</Link>
              </p>
              <p>
                <Link to="/boston-taxi-services">Boston Taxi</Link>
              </p>
              <p>
                <Link to="/logan-airport-shuttle">Logan Airport Shuttle</Link>
              </p>
            </div>
          </div>
          <div className={styles.three} id="footer_contact_list">
            <div className={styles.subThreeTwo}>
              <h3>Get in touch</h3>
              <p>
                <span>Office:</span> Natick, MA 01760
              </p>
              <p>
                <span>Phone:</span> <a href="tel:5089076115">(508) 907-6115</a>
              </p>
              <p>
                <span>Email:</span>{" "}
                <a href="mailto:info@natickairporttaxi.com">
                  info@natickairporttaxi.com
                </a>
              </p>
              <div className="social_media">
                <a href="https://www.facebook.com/natickairporttaxi/" target="_blank">
                  <img src={fb} />
                </a>
                <a href="https://twitter.com/NatickTaxiMA" target="_blank">
                  <img src={twitter} />
                </a>
                <a href="https://www.yelp.com/biz/natick-airport-taxi-and-car-services-natick" target="_blank">
                  <img src={yelp} />
                </a>
                <a href="https://www.tripadvisor.com/Attraction_Review-g41698-d10767393-Reviews-Natick_Airport_Taxi_and_Car_Service-Natick_Massachusetts.html" target="_blank">
                  <img src={trip} />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.bottom2}>
          <p>
            Copyright © {new Date().getFullYear()}{" "}
            <span>Natick Airport Taxi.</span> All Rights Reserved
          </p>
        </div>
      </div>
    </div>
  );
}
export default Footer;

import React, { useEffect, useState } from 'react'
import styles from './Home.module.css'
import background from '../Assets/left.png'
import background2 from './../Assets/group3.png'
import arrow from './../Assets/arrow.png'
import rightarroww from './../Assets/rightarroww.png'
import trip from '../Assets/trip.png'
import add from '../Assets/add.png'
import ReactGoogleAutocomplete from "react-google-autocomplete";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function Home() {
  const mapApiKey = 'AIzaSyCXXZ9VqXu2DV6UfyOLKERn074at4mY8BI';
  const [start, setStart] = useState({
    lat: "",
    lng: "",
    name: "",
  });
  const [end, setEnd] = useState({
    lat: "",
    lng: "",
    name: "",
  });

  const pax = [
    { value: "1", label: "Passenger", isSelected: true, isDisabled: true },
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
  ];
  const lug = [
    { value: "0", label: "Luggage", isSelected: true, isDisabled: true },
    { value: "0", label: "0" },
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
  ];

  const infant = [
    { value: "0", label: "Infant seat (0-15 Mon)", isDisabled: true },
    { value: "0", label: "0" },
    { value: "1", label: "1" },
    { value: "2", label: "2" },
  ];
  const regular = [
    { value: "0", label: "Regular Seat (15-36 Mon)", isDisabled: true },
    { value: "0", label: "0" },
    { value: "1", label: "1" },
    { value: "2", label: "2" },
  ];
  const booster = [
    { value: "0", label: "Booster Seat (Above 3 yrs)", isDisabled: true },
    { value: "0", label: "0" },
    { value: "1", label: "1" },
    { value: "2", label: "2" },
  ];


  useEffect(() => {
    setStart({
      lat: 42.3656132,
      lng: -71.0095602,
      name: "Boston Logan International Airport",
    });
  }, [setStart]);

  // Errors
  const [startError, setStartError] = useState("");
  const [endError, setEndError] = useState("");
  const [dateError, setDateError] = useState("");

  const [checked, setChecked] = useState(false);
  const [radioValue, setRadioValue] = useState("from_airport");
  const [showHideChildSeat, setShowHideChildSeat] = useState(false);


  // Date & TIme
  const [startDate, setStartDate] = useState(new Date());
  const [startTime, setStartTime] = useState(new Date());

  const handleChildSeat = (event) => {
    setShowHideChildSeat(event.target.checked)
  };

  const handleFromTo = (e) => {
    if (e === "to_airport") {
      setRadioValue("to_airport");
      setEnd({
        lat: 42.3656132,
        lng: -71.0095602,
        name: "Boston Logan International Airport",
      });
      setStart({ lat: "", lng: "", name: "" });
    } else {
      setRadioValue("from_airport");
      setStart({
        lat: 42.3656132,
        lng: -71.0095602,
        name: "Boston Logan International Airport",
      });
      setEnd({ lat: "", lng: "", name: "" });
    }
    setEndError("");
    setStartError("");
  };

  // Form Submit
  const handleSubmit = (e) => {
    e.preventDefault();
    if (start.name && end.name) {
      const data = {
        service_type: radioValue,
        from: start.name,
        to: end.name,
        start_lat: start.lat,
        start_lng: start.lng,
        end_lat: end.lat,
        end_lng: end.lng,
        pick_date: e.target[2].value,
        pick_time: e.target[3].value,
        passengers: e.target[4].value,
        luggage: e.target[5].value,
        infant_seat: e.target[7] ? e.target[7].value : "0",
        regular_seat: e.target[8] ? e.target[8].value : "0",
        booster_seat: e.target[9] ? e.target[9].value : "0",
      };
      window.location.href = `https://booking.natickairporttaxi.com/quote?service_type=${data.service_type}&from=${data.from}&start_lat=${data.start_lat}&start_lng=${data.start_lng}&to=${data.to}&end_lat=${data.end_lat}&end_lng=${data.end_lng}&pick_date=${data.pick_date}&pick_time=${data.pick_time}&passengers=${data.passengers}&luggage=${data.luggage}&infant_seat=${data.infant_seat}&regular_seat=${data.regular_seat}&booster_seat=${data.booster_seat}`;
    } else {
      if (!start.name) {
        setStartError("Please fillup Pickup Address");
      }

      if (!end.name) {
        setEndError("Please fillup Drop off Address");
      }
    }
  };

  return (
    <div className={styles.main} >
      <div className={styles.left}>
        <div className={styles.header}>
          <h1>NO SURCHARGE</h1>
          <h1>RELIABLE</h1>
          <h1>TRAVEL STRESS FREE</h1>
        </div>
        <div className={styles.content}>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Faucibus urna, pulvinar lorem in ultrices nulla nisi vel. Fusce commodo ultricies odio sit odio. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Faucibus urna, pulvinar lorem in ultrices nulla nisi vel. </p>
          <button className={styles.getQuote}>Get Quote</button>
        </div>
        <img className={styles.trip} src={trip} alt="milestone" />
      </div>
      <div className={styles.right} style={{ backgroundImage: `url(${background})`, backgroundPosition: "-1.2vh -9.51vw", backgroundRepeat: 'no-repeat', backgroundSize: 'contain' }} >
      </div>
      <div className={styles.new} style={{ backgroundImage: `url(${background})`, backgroundRepeat: 'no-repeat', backgroundSize: 'contain' }} >
        <div className={styles.abc}>

          <div className={styles.header}>
            <h1>A RELIABLE AIRPORT </h1>
            <h1>TAXI TO YOUR</h1>
            <h1>DESTINATION IN TOWN</h1>
          </div>
          <div className={styles.content}>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Faucibus urna, pulvinar lorem in ultrices nulla nisi vel. Fusce commodo ultricies odio sit odio. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Faucibus urna, pulvinar lorem in ultrices nulla nisi vel. </p>
            <button className={styles.getQuote}>Get Started</button>
          </div>
          <img className={styles.trip} src={trip} alt="milestone" />
        </div>
      </div>

      <div className={styles.input}>
        <div className={styles.headd}>
          <h1>Get A Quote</h1>
        </div>
        <form onSubmit={handleSubmit}>
          <div className={styles.Mainss}>
            <div className={styles.leftArrow}>
              <img className={styles.arrow} src={arrow} />
            </div>
            <div className={styles.inputs}>
              {radioValue == "from_airport" ? (
                <input className={styles.input1} placeholder='Pick up Address' type="text" value="Boston Logan International Airport (BOS), Boston, MA, USA" readOnly />
              ) : (<ReactGoogleAutocomplete
                apiKey={mapApiKey}
                placeholder="Pickup Address"
                className={styles.input1}
                onPlaceSelected={(place) => {
                  setStart({
                    lat: place.geometry.location.lat(),
                    lng: place.geometry.location.lng(),
                    name: place.formatted_address,
                  });
                }}
                options={{
                  types: ["geocode", "establishment"],
                  componentRestrictions: { country: ["us"] },
                }}
                onClick={() => setStartError("")}
              />
              )}
              <p className="text-danger">{startError}</p>

              {radioValue == "to_airport" ? (
                <input className={styles.input2} placeholder="Drop Off Address" type="text" value="Boston Logan International Airport (BOS), Boston, MA, USA" readOnly />
              ) : (<ReactGoogleAutocomplete
                apiKey={mapApiKey}
                placeholder="Drop Off Address"
                className={styles.input2}
                onPlaceSelected={(place) => {
                  setEnd({
                    lat: place.geometry.location.lat(),
                    lng: place.geometry.location.lng(),
                    name: place.formatted_address,
                  });
                }}
                options={{
                  types: ["geocode", "establishment"],
                  componentRestrictions: { country: ["us"] },
                }}
                onClick={() => setEndError("")}
              />
              )}
              <p className="text-danger">{endError}</p>


            </div>
            <div className={styles.RightArrow} onClick={(e) => handleFromTo(radioValue == 'from_airport' ? 'to_airport' : 'from_airport')}>
              <img className={styles.rightarroww} src={rightarroww} />
            </div>
          </div>
          <div className={styles.ll}>
            <div className={styles.datetime}>
              <ReactDatePicker
                selected={startDate}
                className={styles.date}
                onChange={(date) => setStartDate(date)}
                minDate={new Date()}
                placeholderText="Pickup Date"
              />
              <ReactDatePicker
                selected={startTime}
                onChange={(date) => setStartTime(date)}
                showTime={{ use12Hours: true, format: "HH:mm a" }}
                showTimeSelectOnly
                showTimeSelect
                dateFormat="hh:mm a"
                placeholderText="Pickup Time"
                timeIntervals={15}
                type="text"
                className={styles.date}
              />
            </div>
            <div className={styles.datetime2}>
              <select className={styles.children}>
                {pax.map(p => (
                  <option value={p.value}>{p.label}</option>
                ))}
              </select>
              <select className={styles.bag}>
                {lug.map(l => (
                  <option value={l.value}>{l.label}</option>
                ))}
              </select>
            </div>
          </div>
          <div className={styles.add}>
            <div className={styles.sub}>
              <label><input type="checkbox" onChange={(e) => handleChildSeat(e)} /> Add Child Seat</label>
            </div>
          </div>

          <div style={{ display: showHideChildSeat ? '' : 'none' }}>
            <select className={styles.children}>
              {infant.map(i => (
                <option value={i.value}>{i.label}</option>
              ))}
            </select>
            <select className={styles.children}>
              {regular.map(i => (
                <option value={i.value}>{i.label}</option>
              ))}
            </select>
            <select className={styles.children}>
              {booster.map(i => (
                <option value={i.value}>{i.label}</option>
              ))}
            </select>
          </div>

          <div className={styles.quoo}>
            <button type="submit">Quote Now</button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default Home
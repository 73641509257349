import React from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import Feature from "../Components/About";

const About = () => {
  return (
    <>
      <Navbar
        SeoTitle="About us Boston Airport Shuttle, Logan , Natick Airport Shuttle"
        SeoDesc="About Our Boston Airport Shuttle and Logan Airport Shuttle are designed to make sure you arrive on time to your destination safely and in comfort."
      />
      <div className="main_container ">
        <div className="section_gap pb_0">
          <h1 className="text_center main_title mb-3">
            SOME KEY FEATURES <span>ABOUT OUR COMPANY</span>
          </h1>
          <p className="text_center mb-5">
            Our Airport car service is available at the most affordable prices.
            When you get in touch with us, all you need to pay for is your usage
            and no hidden costs are involved. We also offer baby seats at a
            small fee to make sure that our little passengers travel safe. We
            also provide shuttle services in Boston and Logan.
          </p>
          <h4 className="mb-2">
            Airport Taxi and Car Natick MA has been offering safe, clean and
            punctual car transportation service
          </h4>
          <p className="mb-3">
            A leading airport transportation service provider from / to the
            Natick and Greater Boston area, we have been serving the people who
            are coming in and out of the Natick Airport for quite some time and
            our high quality services have garnered us much praise from our
            customers and industry insiders.
          </p>
          <h4 className="mb-2">
            Whether you are looking for simple airport transport, corporate
            transport or city wide tours, you can count on us for providing you
            with the best services
          </h4>
          <p>
            Our fleet of vehicles is driven by some of the best drivers in the
            city. Not only are they knowledgeable about the various streets and
            roads of the city but they are also polite, congenial and great
            company to talk to. Being aware of all the major landmarks and
            attractions of the city, they can also take you through a city-wide
            tour. Alternately, if you want to know about the best places in the
            city to eat or enjoy some good nightlife, you can ask for their
            expertise as well. We offer transportation services for pickups and
            drop offs to and from the airport, corporate traveling services, car
            tours and chauffeur services for special occasions like weddings and
            get-togethers. All you need to do is use the easily navigable car
            booking facilities in our site to book a cab for yourself and
            benefit from our highly experienced chauffeured car services. All of
            our vehicles are maintained well for the best driving and riding
            experience. So when you are with us, you can expect the most
            comfortable and safe traveling experience. Our luxury sedans, SUVs
            and minivans can take you to your destination on time and ensure
            that you are the best health and mind to enjoy every moment of your
            experience. All our car services are also available at the most
            competitive prices.
          </p>
        </div>
      </div>
      <Feature />
      <Footer />
    </>
  );
};

export default About;
